<template>
  <div class="about">
    <div class="about-search">
      <div class="about-search-input">

        <span class="required">*</span>
        <el-select v-model="formInline.modelId" placeholder="请选择流程名称" filterable :popper-append-to-body="false"
          style="width: 200px;" @change="search" class="sx-select">
          <el-option v-for="dict in modelIdList" :key="dict.modelId" :label="dict.name" :value="dict.modelId" />
        </el-select>
        <span class="required" style="margin-left: 8px">*</span>
        <el-date-picker v-model="formInline.startTime" @change="search"
          style="border:0px;background-color: rgb(243, 244, 246);width: 220px;height: 34px;border-radius: 6px;"
          align="left" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-" start-placeholder="开始日期"
          end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" :append-to-body="false" size="mini" />
      </div>

      <div class="about-search-input">
        <el-popover placement="left" trigger="click" :append-to-body="false" width="366" :popper-options="{}">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" :label-position="'top'">
            <el-form-item label="审批状态" style="width: 47%;">
              <el-select v-model="formInline.status" placeholder="请选择审批状态" clearable :popper-append-to-body="false">
                <el-option v-for="dict in approvalList" :key="dict.value" :label="dict.label" :value="dict.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="启用状态" style="width: 47%;">
              <el-select v-model="formInline.defStatus" placeholder="请选择启用状态" clearable :popper-append-to-body="false">
                <el-option v-for="dict in openList" :key="dict.value" :label="dict.label" :value="dict.value" />
              </el-select>
            </el-form-item>
          </el-form>


          <el-form :inline="true" :model="formInline" class="demo-form-inline" :label-position="'top'"
            style="width: 100%;">
            <el-form-item label="发起人" style="width: 100%;">
              <el-select style="width: 100%;" v-model="formInline.startUserId" filterable placeholder="请选择发起人"
                :popper-append-to-body="false">
                <el-option v-for="item in UserList" :key="item.userId" :label="item.userName" :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="完成时间" style="width: 100%;">
              <el-date-picker align="center" style="width: 100%;height: 40px;" v-model="formInline.endTime"
                value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" :append-to-body="false" size="mini" />
            </el-form-item>
          </el-form>

          <div class="sx-btn-box">
            <div class="sx-btn" @click="reset">
              重置
            </div>
            <div class="sx-btns" @click="search">
              搜索
            </div>
          </div>

          <div class="about-search-input-item" slot="reference">
            <img src="@/assets/ioc/menu-right-alt.png" alt="">
            <span>高级筛选</span>
          </div>

        </el-popover>

        <div class="about-search-input-item" @click="$router.push({name:'OperationRecord'})">
          <img src="@/assets/ioc/clipboard-list.png" alt="">
          <span>操作记录</span>
        </div>

        <div class="about-search-input-item" @click="handleExportBeach">
          <img src="@/assets/ioc/arrow-up-from-line.png" alt="">
          <span>批量导出</span>
        </div>

      </div>
    </div>

    <div class="about-table" v-show="tableData.length > 0">
      <el-table :data="tableData" style="width: 100%" :header-cell-style="tableHeaderColor"
        :row-class-name="tableRowClassName" height="calc(100vh - 230px)" @row-click="handleClick"
        @selection-change="handleSelectionChange" v-loading="loading" ref="featureRef">
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column label="流程名称" align="center">
          <template slot-scope="scope">
            <div class="about-table-name">
              <img style="width: 24px; height: 24px; margin-right: 8px;"
                :src="scope.row.initiator.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
              <span style="color: rgb(86, 105, 236)" @click.stop="UserClick(scope.row, scope.column, $event)">{{
                scope.row.initiator.userName
                }}<span style="color: rgb(27, 27, 27);margin-left: 2px;">提交的</span></span>
            </div>
            <div style="text-align: left;">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="提交时间" align="center">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.startTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="流程状态" align="center">
          <template slot-scope="scope">
            <span>{{ styleObj[scope.row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="结束时间" align="center">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.endTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="历史审批人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.historyApprover }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <span style="color: rgb(86, 105, 236); margin-right: 16px;"
                @click.stop="handleExport(scope.row)">导出</span>
              <span :style="{ color: (scope.row.taskId ? 'rgb(86, 105, 236)' : 'rgb(195, 196, 204)') }"
                @click.stop="handleTran(scope.row)">转交</span>
              <span style=" margin-left: 16px; color:rgb(248, 62, 69)" @click.stop="handleDelete(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-show="tableData.length == 0" class="about-img-box">
      <!-- <img src="https://source.fungsong.com/3453289031113U1XePlw7taov.png" alt=""> -->
      <img class="about-img" src="https://source.fungsong.com/3453288888914YKG1W1O2ZTgm.png" alt="">
      <div class="about-img-text" v-if="!formInline.modelId && !formInline.startTime">
        选择<span style="color: rgb(86, 105, 236);">流程名称日期范围</span>后显示内容
      </div>
    </div>


    <processDetails ref="processDetails" :drawerData="drawerData"></processDetails>


    <el-dialog title="" :visible.sync="dialogVisible" width="30%" custom-class="transfer-custom"
      :modal-append-to-body="false" :center="false">
      <div class="dialog-footer dialog-footer-btn">
        <div class="dialog-footer-btn-item">{{ dialogTxt.title }}</div>
        <el-checkbox-group v-model="checkList" v-if="dialogTxt.title == '批量导出'">
          <el-checkbox label="表单"></el-checkbox>
          <el-checkbox label="附件"></el-checkbox>
        </el-checkbox-group>
        <div class="dialog-item">{{ dialogTxt.content }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div @click="dialogVisible = false" class="cancel">
          取消
        </div>
        <div @click="debounceDetermineTheEvent" class="submit">
          确定
        </div>
      </span>
    </el-dialog>


    <!-- 对话框(转办审批人) -->
    <el-dialog title="转交" :visible.sync="open" width="368px" :modal-append-to-body="false"
      :custom-class="'handover-dialog'">
      <el-form ref="form" :model="form" :rules="rules" label-width="110px" :label-position="'top'">
        <el-form-item label="新审批人" prop="assigneeUserId">
          <el-select v-model="form.assigneeUserId" filterable clearable style="width: 100%"
            :popper-append-to-body="false">
            <el-option v-for="item in userOptions" :key="item.userId" :label="item.userName" :value="item.userId" />
          </el-select>
        </el-form-item>
        <el-form-item label="转交理由" prop="reason">
          <el-input v-model="form.reason" clearable placeholder="请输入转交理由" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div @click="open=false" class="dialog-footer-btn-cc">
          取消
        </div>
        <div @click="submitUpdateDelegateForm" class="dialog-footer-btn-determine">
          确定
        </div>
      </div>
    </el-dialog>

  </div>
</template>


<script>
  import { debounce } from 'lodash-es';
  import { getFlowDefList, getUserList, getModelList, getExportDataList, deleteFlowData, exportAnnex } from "@/api/bpm/dataManagement.js";
  import { getFlowManagementList, getArrSimpleList } from '@/api/examines.js'
  import { parseTime } from "@/utils/time";
  import { updateTaskAssignee } from "@/api/bpm/task";
  // import Detail from '@/views/detail/index'
  import { listSimpleUsers } from "@/api/system/user";
  import processDetails from '@/components/processDetails.vue';
  export default {
    components: {
      // Detail,
      processDetails
    },

    computed: {
      selectedSponsorLabel() {
        const selectedUser = this.UserList.find(item => item.userId === this.formInline.startUserId);
        return selectedUser ? selectedUser.userName : '';
      },
      backgroundColor() {
        switch (this.state) {
          case 1://进行中
            return 'custom-drawer-haveInHand'
            break;
          case 2://已通过
            return 'custom-drawer-adopt'
            break;
          case 3://未通过
            return 'custom-drawer-notPassed'
            break;
          case 4://已撤销
            return 'custom-drawer-revoke'
            break;
          default:
            return 'custom-drawer-haveInHand'
            break;
        }
      }
    },
    data() {
      return {
        debounceHandleExportConfir: '',
        debounceDetermineTheEvent: '',
        checkList: ['表单'],
        state: 1,
        loading: false,
        defName: '',
        total: 0,
        multipleSelection: [],
        dialogTxt: {
          title: '删除',
          content: '是否确认删除？'
        },
        userOptions: [],
        rules: {
          reason: [
            { required: true, message: '请输入委派理由', trigger: 'blur' },
          ],
          assigneeUserId: [
            { required: true, message: '请选择新审批人', trigger: 'change' },
          ]
        },
        form: {
          assigneeUserId: '',
          reason: '',
          id: '',
          isValid: true,
        },
        currentData: {},
        open: false,
        dialogVisible: false,
        UserList: [],
        modelIdList: [],
        drawerData: {},
        parseTime,
        formInline: {
          name: '',
          startUserId: '',//发起人
          modelId: '',//流程名称
          startTime: [],//发起时间
          endTime: [],//结束时间
          status: '',//审批状态
          defStatus: '',//启用状态
        },
        styleObj: {
          '1': '进行中',
          '2': '已完成',
          '3': '已撤回',
        },
        openList: [
          { label: "启用", value: 0 },
          { label: "停用", value: 1 },
        ],
        approvalList: [
          { label: "进行中", value: 1 },
          { label: "已完成", value: 2 },
          { label: "已取消", value: 3 },
        ],
        tableData: []
      };
    },
    async created() {
      await this.getList();
      await this.handleSelect()
      await this.getModelList();
      await this.getUserList();
      this.debounceHandleExportConfir = debounce(this.handleExportConfirm, 500)
      this.debounceDetermineTheEvent = debounce(this.determineTheEvent, 500)
    },
    activated() {
      this.$refs.featureRef.doLayout()
    },
    methods: {
      setBackgColor(strg) {
        if (strg.status == 2) {
          this.state = strg.result == 2 ? 2 : 3
        } else {
          this.state = strg.status == 3 ? 4 : 1
        }
      },
      tableHeaderColor({ row, column, rowIndex, columnIndex }) {
        if (rowIndex === 0) {
          switch (columnIndex) {
            case 0:
              return { 'background': 'rgb(86, 105, 236)', 'border-radius': '6px 0px 0px 0px', 'color': 'rgb(250, 251, 252)' };
              break;
            case 6:
              return { 'background': 'rgb(86, 105, 236)', 'border-radius': '0px 6px 0px 0px', 'color': 'rgb(250, 251, 252)' };
              break;
            default:
              return { 'background': 'rgb(86, 105, 236)', 'color': 'rgb(250, 251, 252)' };
              break;
          }
        }
      },
      tableRowClassName({ row, rowIndex }) {
        return 'success-row';
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      // 获取数据
      async getList() {
        if (this.formInline.modelId && this.formInline.startTime) {
          const query = {
            pageNo: 1,
            pageSize: 99999,
            defStatus: this.formInline.defStatus,
            endTime: this.formInline.endTime || [],
            modelId: this.formInline.modelId,
            startTime: this.formInline.startTime,
            startUserId: this.formInline.startUserId,
            status: this.formInline.status,
          }
          this.loading = true;
          const { data } = await getFlowDefList(query);
          if (data) {
            this.tableData = data.list;
            this.total = data.total;
            if (data.total > 0) {
              this.defName = data.list[0].defName;
            }
          }
          this.loading = false;
        }
      },

      // 获取用户列表
      async getUserList() {
        //  
        const { data } = await listSimpleUsers()
        this.userOptions = data;
      },

      // 获取流程列表
      async getModelList() {
        const { data } = await getModelList();
        this.modelIdList = data
      },

      //获取人员名称
      async handleSelect() {
        const { rows } = await getArrSimpleList();
        this.UserList = rows
      },

      // 行点击事件
      handleClick(row, column, event) {
        this.drawerData = {
          id: row.processInstanceId,
          taskType: '4',
        }
        this.$refs.processDetails.drawer = true;
      },

      // 点击用户卡片
      UserClick(row, column, event) {
        this.openUserCard(row.initiator.userId)
      },

      // 重置
      reset() {
        this.formInline = {
          name: '',
          startUserId: '',//发起人
          modelId: '',//流程名称
          startTime: [],//发起时间
          endTime: [],//结束时间
          status: '',//审批状态
          defStatus: '',//启用状态
        };
        this.tableData = [];
        this.total = 0;
      },

      // 搜索
      search() {
        if (this.formInline.modelId && this.formInline.startTime.length > 0) {
          this.getList();
          return true
        }
        this.$message({
          message: '请选择流程名称和开始时间',
          type: 'warning'
        });
        return false
      },

      // 确定事件
      determineTheEvent() {
        if (this.dialogTxt.title == '删除') {
          this.handleConfirm()
        }
        if (this.dialogTxt.title == '导出') {
          this.debounceHandleExportConfir()
        }
        if (this.dialogTxt.title == '批量导出') {
          if (this.checkList.includes('表单')) this.batchExport()
          if (this.checkList.includes('附件')) this.handleExportAttachment()
        }
      },

      //删除
      handleDelete(row) {
        this.currentData = row
        this.dialogTxt.title = '删除'
        this.dialogTxt.content = '是否确认删除？'
        this.dialogVisible = true;
      },

      // 拼接请求参数
      handleExportParams() {
        let dataArr = []
        dataArr.push(this.modelIdList.find((err) => err.modelId == this.formInline.modelId).name)
        if (this.formInline.startTime.length > 0) {
          let str = this.formInline.startTime[0] + "-" + this.formInline.startTime[1] + '(发起时间)'
          dataArr.push(str)
        }
        if (this.formInline.endTime.length > 0) {
          let str = this.formInline.endTime[0] + "-" + this.formInline.endTime[1] + '(完成时间)'
          dataArr.push(str)
        }
        if (this.formInline.status) {
          dataArr.push(this.formInline.status == 1 ? '进行中' : this.formInline.status == 2 ? '已完成' : '已取消')
        }
        if (this.formInline.defStatus == 0 || 1) {
          dataArr.push(this.formInline.defStatus == 0 ? '启用' : '停用')
        }
        if (this.formInline.startUserId) {
          dataArr.push(this.selectedSponsorLabel)
        }
        return dataArr.join(', ')
      },

      // 确认删除
      handleConfirm() {
        let row = this.currentData
        let dataArr = []
        dataArr.push(row.defName)
        if (this.formInline.startTime.length > 0) {
          let str = this.formInline.startTime[0] + "-" + this.formInline.startTime[1] + '(发起时间)'
          dataArr.push(str)
        }
        if (this.formInline.endTime.length > 0) {
          let str = this.formInline.endTime[0] + "-" + this.formInline.endTime[1] + '(完成时间)'
          dataArr.push(str)
        }
        if (this.formInline.status) {
          dataArr.push(this.formInline.status == 1 ? '进行中' : this.formInline.status == 2 ? '已完成' : '已取消')
        }
        if (this.formInline.defStatus == 0 || 1) {
          dataArr.push(this.formInline.defStatus == 0 ? '启用' : '停用')
        }
        if (this.formInline.startUserId) {
          dataArr.push(this.selectedSponsorLabel)
        }
        let dataStr = dataArr.join(', ')

        let data = { ids: [row.processInstanceId], queryCon: dataStr }
        deleteFlowData(data).then(() => {
          this.$message.success("删除成功");
          this.dialogVisible = false;
          this.getList()
        });
      },

      // 导出
      handleExport(row) {
        this.currentData = row
        this.dialogTxt.title = '导出'
        this.dialogTxt.content = '是否确认导出？'
        this.dialogVisible = true;
      },

      // 确认导出
      handleExportConfirm() {
        let row = this.currentData
        let arr = [];
        arr.push(row.processInstanceId)
        let data = { ids: arr, modelId: this.formInline.modelId, startTime: this.formInline.startTime, defName: row.defName }
        getExportDataList(data).then(() => {
          this.$message.success("导出成功！可在操作记录中查看下载");
          this.dialogVisible = false;
        })
      },

      // 转交
      handleTran(row) {
        if (row.taskId) {
          this.resetUpdateAssigneeForm();
          this.form.id = row.taskId;
          this.open = true;
        }
      },

      /** 重置转办审批人 */
      resetUpdateAssigneeForm() {
        this.form = {
          id: undefined,
          assigneeUserId: undefined,
          reason: undefined,
          isValid: true
        };
      },

      // 取消转办
      cancelUpdateDelegateForm() {
        this.open = false;
        this.resetUpdateAssigneeForm();
      },

      // 确定转办
      submitUpdateDelegateForm() {
        updateTaskAssignee(this.form).then((response) => {
          this.$modal.msgSuccess("转办任务成功！");
          this.open = false;
          this.getList();
        });
      },

      // 批量导出
      handleExportBeach() {
        this.dialogTxt.title = '批量导出'
        if (this.multipleSelection.length > 0) {
          this.dialogTxt.content = `是否确认批量导出选中的${this.multipleSelection.length}条数据？`
        } else {
          this.dialogTxt.content = `是否确认批量导出选中的${this.total}条数据？`
        }
        this.dialogVisible = true;
      },

      // 确定批量导出
      batchExport() {
        let data = {}
        if (this.multipleSelection.length > 0) {
          let arr = this.multipleSelection.map(i => {
            return i.processInstanceId
          })
          data = { ids: arr, modelId: this.formInline.modelId, startTime: this.formInline.startTime, defName: this.defName }
        } else {
           
          data = { ...this.formInline };
          data.pageNo = undefined;
          data.pageSize = undefined;
          data.defName = this.defName;
          delete data.endTime;
        }
        getExportDataList(data).then(() => {
          this.$message.success("表单导出成功！可在操作记录中查看下载");
          this.dialogVisible = false;
        });

      },

      // 附件批量导出
      handleExportAttachment() {
        let data = {
          queryCon: this.handleExportParams(),
        }
        if (this.multipleSelection.length > 0) {
          let arr = this.multipleSelection.map(i => {
            return i.processInstanceId
          })
          data = { ...data, ...{ ids: arr, modelId: this.formInline.modelId, startTime: this.formInline.startTime, defName: this.defName } }
        } else {
          data = { ...data, ...this.formInline };
          data.pageNo = undefined;
          data.pageSize = undefined;
          data.defName = this.defName;
          delete data.endTime;
        };
        exportAnnex(data).then((err) => {
          if (err.code == 200) {
            this.$message.success("附件导出成功！可在操作记录中查看下载");
          }
          this.dialogVisible = false;
        });
      }

    }

  };
</script>

<style lang="scss" scoped>
  .required {
    color: red;
    margin-right: 5px;
    margin-top: 5px;
  }

  .el-form-item__label:after {
    content: "*";
    color: red;
    margin-left: 5px;
  }

  ::v-deep .custom-drawer-haveInHand {
    background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(86, 105, 236, 0.3) 100%), rgba(255, 255, 255, 1);
  }

  ::v-deep .custom-drawer-adopt {
    background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(93, 162, 172, 0.3) 100%), rgb(255, 255, 255);
  }

  ::v-deep .custom-drawer-notPassed {
    background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(250, 101, 89, 0.3) 100%), rgba(255, 255, 255, 1);
  }

  ::v-deep .custom-drawer-revoke {
    background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(188, 188, 188, 0.3) 100%), rgb(255, 255, 255);
  }

  ::v-deep #el-drawer__title {
    margin-bottom: 8px;
  }

  ::v-deep .el-drawer__body {
    flex: 1 1 0%;
    overflow: hidden;
    padding: 0px;
  }

  ::v-deep .el-form-item--small .el-form-item__label {
    line-height: 32px;
    height: 32px;
    margin-left: 4px;
  }

  .drawer__title {
    text-align: left;
    color: rgba(27, 27, 27, 1);
    font-family: "Noto Sans SC";
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }


  .about {
    padding: 12px 16px 24px 16px;

    .about-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .about-search-input {
      display: flex;
      align-items: center;

      .about-search-input-item {
        width: 96px;
        height: 34px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        background: rgb(243, 244, 246);
        justify-content: space-evenly;
        margin-right: 8px;

        span {
          color: rgba(27, 27, 27, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
        }
      }
    }

    .about-table {
      margin-top: 16px;
      height: calc(100vh - 50px);

      .about-table-name {
        display: flex;
      }

      ::v-deep .el-table__body-wrapper {
        background-color: rgb(243, 244, 246) !important;
      }

      ::v-deep .el-table__row {
        background-color: rgb(243, 244, 246) !important;
      }
    }

  }

  /* ::v-deep.el-input {

    .el-input__inner {
      line-height: 0px;
      padding-left: 38px;
      background: rgb(243, 244, 246);
      border: 0px;
      border-radius: 6px;
    }
  } */

  ::v-deep .el-select {

    /* .el-input__inner {
      background: rgb(243, 244, 246);
      border: 0px;
      border-radius: 6px;
      padding-left: 38px;
    } */

    /* 自定义图标和输入框的对齐 */
    .custom-icon {
      font-size: 14px;
      display: flex;
      align-items: center;
      /* 垂直居中对齐 */
      height: 100%;
      /* 占满父元素高度 */
      padding-left: 10px;
    }

    /* 调整 el-select 的高度以确保图标和文字对齐 */
    .el-select .el-input__inner {
      display: flex;
      align-items: center;
    }
  }


  .about-img-box {
    /* position: absolute; */
    /* top: 50%;
    left: 50%; */
    height: 300px;

    .about-img {
      position: relative;
      top: 88px;
      left: -30px;
    }

    .about-img-text {
      position: relative;
      top: 88px;
      left: -20px;
      text-align: center;
      color: rgba(132, 132, 132, 1);
      font-family: "Noto Sans SC";
      font-weight: 400;
      font-size: 14px;
    }
  }

  .sx-btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .sx-btn {
      width: 112px;
      height: 44px;
      border-radius: 2px;
      background: rgba(243, 244, 246, 1);
      line-height: 44px;
      text-align: center;
      color: var(--, rgba(132, 132, 132, 1));
      font-size: 13px;
    }

    .sx-btns {
      width: 204px;
      height: 44px;
      border-radius: 2px;
      background: rgba(86, 105, 236, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 13px;
      line-height: 44px;
      text-align: center;
    }

  }

  .dialog-footer {
    text-align: left;
    /* 将按钮对齐左边 */
  }

  .dialog-footer-btn {
    position: relative;
    top: -32px;
    left: 20px;
  }

  .dialog-footer-btn-item {
    color: rgba(27, 27, 27, 1);
    font-family: "Noto Sans SC";
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .dialog-item {
    color: rgba(132, 132, 132, 1);
    font-family: "Noto Sans SC";
    font-weight: 350;
    font-size: 12px;
    line-height: 12px;
    /* margin-top: 12px; */
  }

  ::v-deep .transfer-custom {
    height: 180px;

    .el-dialog__header {
      .el-dialog__headerbtn {
        top: 16px;
        right: 8px;
      }
    }

    .el-dialog__footer {
      .dialog-footer {
        display: flex;
        justify-content: flex-end;

        .cancel {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .submit {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  ::v-deep .el-dialog__header {
    text-align: left;
  }

  ::v-deep .sx-select {
    .el-input__inner {
      line-height: 0px;
      padding-left: 38px;
      background: rgb(243, 244, 246);
      border: 0px;
      border-radius: 6px;
      height: 34px;
    }
  }

  ::v-deep .handover-dialog {
    .el-dialog__header {
      display: flex;

      .el-dialog__headerbtn {
        top: 8px;
        right: 0px;
      }
    }

    .el-dialog__body {
      padding: 20px 20px;

      .el-form-item {
        display: grid;
        margin-bottom: 18px !important;

        .el-form-item__label {
          margin-bottom: 12px;
        }

        .el-form-item__content {
          .el-input__inner {
            background: #fff !important;
            border: 1px solid rgb(220, 223, 230) !important;
          }
        }
      }
    }

    .el-dialog__footer {
      .dialog-footer {
        display: flex;

        .dialog-footer-btn-cc {
          width: 110px;
          height: 44px;
          border-radius: 8px;
          background: rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .dialog-footer-btn-determine {
          width: 208px;
          height: 44px;
          border-radius: 8px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
</style>