<template>
    <div>
        <!-- 撤销流程 -->
        <el-dialog title="关联审批单" :visible.sync="revokeShow" width="600px" :modal-append-to-body="false"
            custom-class="_relatedApproval">

            <el-tabs v-model="type" @tab-click="handleClick" tab-position="top">
                <el-tab-pane label="我审批的" name="0"></el-tab-pane>
                <el-tab-pane label="我发起的" name="1"></el-tab-pane>
                <el-tab-pane label="我收到的" name="2"></el-tab-pane>
            </el-tabs>

            <el-input placeholder="流程名称/摘要/发起人" prefix-icon="el-icon-search" v-model="name"
                @input="deGetRelationApprovalDetail">
            </el-input>

            <div class=" select-box">
                <span>审批类型 </span>
                <el-select v-model="formId" placeholder="审批类型" style="width: 150px;"
                    @change="getRelationApprovalDetail">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <div class="tab-content">
                <div class="tab-pane" v-for="item in list" :key="item.processInstanceId">
                    <div class="tab-pane-header">
                        <div class="tab-pane-header-left">
                            <div style="margin-right: 5px;">
                                <input type="checkbox" :value="item" v-model="selectedItems" />
                            </div>
                            <div>
                                <img v-if="item.initiator || item.initiator.avatarUrl" :src="item.initiator.avatarUrl"
                                    alt="">
                                <img v-else src="@/assets/ioc/Group_41.png" alt="">
                            </div>
                            <div class="tab-pane-header-right">
                                <div class="tab-pane-header-right-title">{{item.name}}</div>
                                <div class="tab-pane-header-right-time">提交于{{ formattedTime(item.createTime) }}
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane-status">
                            <span v-if="item.status == 1" style="color: #5669ec;">进行中</span>
                            <span v-if="item.status == 2 && item.result == 2" style="color: #67c23a;">已通过</span>
                            <span v-if="item.status == 2 && item.result == 3" style="color: #f83e45;">不通过</span>
                            <span v-if="item.status == 3" style="color: #848484;">已撤回</span>
                        </div>
                    </div>
                    <div class="tab-pane-content"></div>
                    <div class="tab-pane-footer">
                        <div v-for="(row,index) in handleDigest(item.digest)" :key="index" class="tab-pane-footer-item"
                            :style="">
                            <span class="tab-pane-footer-title">{{row.name}}</span>
                            <span>{{row.value}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <div @click="revokeShow = false" class="dialog-footer-btn">
                    取消
                </div>
                <div @click="OnDetermine" class="dialog-footer-btn-determine">
                    确定
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import { relationApprovalDetail, findApproveForm } from "@/api/bpm/flow.js";
    import { parseTime } from "@/utils/time";


    export default {
        name: 'HFIMElectronWorkbenchIndex',

        data() {
            return {
                type: "0",
                name: '',
                list: [],
                revokeShow: false,
                formId: '',
                options: [],
                selectedItems: [],
                formIds: '',
                __vModel__: '',
                deGetRelationApprovalDetail: '',
            };
        },

        watch: {
            revokeShow(newValue, oldValue) {
                if (!newValue) {
                    this.clearValue();
                } else {
                    this.updateData();
                }
            }
        },

        async mounted() {
            this.deGetRelationApprovalDetail = debounce(this.getRelationApprovalDetail, 200);
        },

        methods: {
            // 更新数据
            async updateData() {
                await this.getfindApproveForm();
                this.formId = this.options[0].id;
                await this.getRelationApprovalDetail();
            },
            // 获取关联审批数据
            async getRelationApprovalDetail() {
                const { data } = await relationApprovalDetail(Number(this.type), this.name, this.formId);
                this.list = data;
            },
            // 获取审批类型
            async getfindApproveForm() {
                const { data } = await findApproveForm(this.formIds);
                this.options = data;
            },
            // 确定事件
            OnDetermine() {
                this.$emit('determine', this.selectedItems, this.__vModel__, this.$parent);
                this.revokeShow = false;
            },
            // 时间转化
            formattedTime(time) {
                return parseTime(time);
            },
            // 切换状态
            handleClick(tab, event) {
                this.getRelationApprovalDetail();
            },
            // 转化摘要
            handleDigest(digest) {
                try {
                    const digestList = JSON.parse(digest)
                    return digestList
                } catch (error) {
                    return []
                }
            },
            // 清空值
            clearValue() {
                this.type = "0";
                this.name = '';
                this.list = [];
                this.formId = '';
                this.options = [];
                this.selectedItems = [];
                this.formIds = '';
            }
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep .el-tabs__header {
        order: 1;
    }

    ::v-deep .el-dialog__body {
        padding-bottom: 0px !important;
        border-bottom: 1px solid rgba(245, 245, 247, 1);
    }

    ::v-deep .el-dialog__footer {
        display: flex;
        justify-content: flex-end;
    }

    ::v-deep .el-input__inner {
        -webkit-appearance: none;
        background-color: rgb(243, 244, 246);
        background-image: none;
        border-radius: 4px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        height: 35px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        width: 100%;
    }

    ._relatedApproval {

        .select-box {
            margin-top: 11px;
            margin-bottom: 15px;
            text-align: left;
        }

        .tab-content {
            height: 30vh;
            overflow-y: auto;

            .tab-pane {
                border-radius: 4px;
                border: 1px solid rgba(243, 244, 246, 1);
                margin-bottom: 8px;

                .tab-pane-header {
                    padding: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .tab-pane-header-left {
                        display: flex;
                        align-items: center;

                        img {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                        }

                        .tab-pane-header-right {
                            margin-left: 8px;

                            .tab-pane-header-right-title {
                                color: var(--字体黑, rgba(27, 27, 27, 1));
                                font-family: "Noto Sans SC";
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 16px;
                            }

                            .tab-pane-header-right-time {
                                margin-top: 5px;
                                color: var(--次字体颜色, rgba(132, 132, 132, 1));
                                font-family: "Noto Sans SC";
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 10px;
                            }
                        }

                    }

                    .tab-pane-status {
                        font-family: "Noto Sans SC";
                        font-weight: 500;
                        font-size: 14px;
                    }

                }

                .tab-pane-content {
                    transform: rotate(-0.000005deg);
                    border: 1px dashed var(--温柔灰, rgba(228, 229, 231, 1));
                    margin-left: 16px;
                    margin-right: 16px;
                }

                .tab-pane-footer {
                    padding: 16px;
                    color: rgba(175, 175, 175, 1);
                    font-family: "Noto Sans SC";
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 0.5%;

                    .tab-pane-footer-item {
                        margin-bottom: 16px;

                        .tab-pane-footer-title {
                            margin-right: 16px;
                        }
                    }

                    .tab-pane-footer-item:last-child {
                        margin-bottom: 0;
                    }
                }

            }

        }

        .el-dialog__footer {
            .dialog-footer {
                display: flex;

                .dialog-footer-btn {
                    width: 110px;
                    height: 44px;
                    border-radius: 8px;
                    background: rgba(243, 244, 246, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba(132, 132, 132, 1);
                    font-family: "Noto Sans SC";
                    font-weight: 400;
                    font-size: 14px;
                }

                .dialog-footer-btn-determine {
                    width: 208px;
                    height: 44px;
                    border-radius: 8px;
                    background: rgba(86, 105, 236, 1);
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba(255, 255, 255, 1);
                    font-family: "Noto Sans SC";
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }
</style>