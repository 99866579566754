import { deepClone } from "@/utils/index";

import {
  uploadURLFromRegionCode,
  init,
  config,
  DOMAIN,
} from "@/utils/qiniuUploader.js";
import store from '@/store';

const componentChild = {};
/**
 * 将./slots中的文件挂载到对象componentChild上
 * 文件名为key，对应JSON配置中的__config__.tag
 * 文件内容为value，解析JSON配置中的__slot__
 */
const slotsFiles = require.context("./slots", false, /\.js$/);
const keys = slotsFiles.keys() || [];
keys.forEach((key) => {
  const tag = key.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = slotsFiles(key).default;
  componentChild[tag] = value;
});

function vModel(dataObject, defaultValue) {
  dataObject.props.value = defaultValue;
  if (dataObject.attrs.__config__.selectType == "radio") {
    dataObject.on.input = (val) => {
      this.$emit("input", val);
    };
    return true
  }
  if (dataObject.attrs.__config__.type == "approval") {
    dataObject.on.click = (val) => {
      this.$emit("input", val);
    };
    return true
  }
  // else {
  dataObject.on.input = (val) => {
    this.$emit("input", val);
  };
  dataObject.on.change = (val) => {
    this.$emit("change", val);
  };
  // }
}

function mountSlotFiles(h, confClone, children) {
  const childObjs = componentChild[confClone.__config__.tag];
  if (childObjs) {
    Object.keys(childObjs).forEach((key) => {
      const childFunc = childObjs[key];
      if (confClone.__slot__ && confClone.__slot__[key]) {
        children.push(childFunc(h, confClone, key));
      }
    });
  }
}

function emitEvents(confClone) {
  ["on", "nativeOn"].forEach((attr) => {
    const eventKeyList = Object.keys(confClone[attr] || {});
    eventKeyList.forEach((key) => {
      const val = confClone[attr][key];
      if (typeof val === "string") {
        confClone[attr][key] = (event) => this.$emit(val, event);
      }
    });
  });
}

function buildDataObject(confClone, dataObject) {
  Object.keys(confClone).forEach((key) => {
    const val = confClone[key];
    if (key === "__vModel__") {
      vModel.call(this, dataObject, confClone.__config__.defaultValue);
    } else if (dataObject[key] !== undefined) {
      if (
        dataObject[key] === null ||
        dataObject[key] instanceof RegExp ||
        ["boolean", "string", "number", "function"].includes(
          typeof dataObject[key]
        )
      ) {
        dataObject[key] = val;
      } else if (Array.isArray(dataObject[key])) {
        dataObject[key] = [...dataObject[key], ...val];
      } else {
        dataObject[key] = { ...dataObject[key], ...val };
      }
    } else {
      dataObject.attrs[key] = val;
    }
  });

  // 清理属性
  clearAttrs(dataObject);
}

function clearAttrs(dataObject) {
  delete dataObject.attrs.__config__;
  delete dataObject.attrs.__slot__;
  delete dataObject.attrs.__methods__;
}

function compatible(that, confClone) {
  // 关联审批控件改造
  if (confClone.type == "approval") {
    confClone.__config__.tag = "el-button";
    confClone.__slot__ = {
      default: '添加审批单'
    }
    that.conf.__config__.tag = "el-button";
    that.conf.icon = 'el-icon-plus';
    that.conf.style.width = '130px';
    that.conf.__slot__ = {
      default: '添加审批单'
    }
  }
  // 上传控件可复制
  // if (confClone.__config__.tag == "el-upload") {
  //   confClone.style = {
  //     userSelect: 'text'
  //   }

  //   that.conf.style = {
  //     userSelect: 'text'
  //   }
  // }
}

function makeDataObject() {
  // 深入数据对象：
  // https://cn.vuejs.org/v2/guide/render-function.html#%E6%B7%B1%E5%85%A5%E6%95%B0%E6%8D%AE%E5%AF%B9%E8%B1%A1
  return {
    class: {},
    attrs: {},
    props: {},
    domProps: {},
    nativeOn: {},
    on: {},
    style: {},
    directives: [],
    scopedSlots: {},
    slot: null,
    key: null,
    ref: null,
    refInFor: true,
  };
}

// 设置固定组件为只读
function setFixedComponent(conf, dataObject) {
  if (conf.__vModel__ == 'field1724060921495') {
    dataObject.attrs.disabled = true;
  }
  return dataObject;
}

export default {
  props: {
    conf: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uploadAction: "",
      uploadData: {},
      timer: null,
    };
  },
  created() {
    this.initQiniu();
  },
  methods: {
    async initQiniu() {
      // const res = await axios.get(
      //   "https://api.heytopo.com/appapi/?service=Upload.getCosInfo"
      // );
      let uptoken = store.state.imWorkbench.UploadToken.upToken
      const options = {
        // bucket所在区域，这里是华北区。ECN, SCN, NCN, NA, ASG，分别对应七牛云的：华东，华南，华北，北美，新加坡 5 个区域
        region: "SCN",
        uptoken: uptoken,
        uptokenURL: "https://[yourserver.com]/api/uptoken",
        uptokenFunc: function () { },
        domain: "https://source.fungsong.com",
        shouldUseQiniuFileName: false,
      };
      // 将七牛云相关配置初始化进本sdk
      init(options);
      this.uploadAction = uploadURLFromRegionCode(config.qiniuRegion);
      this.uptoken = uptoken;
    },
  },
  render(h) {
    let that = this;
    let dataObject = makeDataObject();
    const confClone = deepClone(this.conf);
    const children = this.$slots.default || [];
    if (confClone.__config__.tag === "el-upload") {
      if (confClone.__config__.defaultValue.length) {
        confClone["file-list"] = confClone.__config__.defaultValue.map(
          (item) => ({
            name: item.fileName,
            url: item.fileUrl,
            type: item.fileType,
          })
        );
      }

      confClone["before-upload"] = function (file) {
        let limitSize = confClone.__config__.fileSize;
        const isLt2M = file.size / 1024 / 1024 < limitSize;
        console.log(`output->isLt2M`, isLt2M)
        if (!isLt2M) {
          this.$message.error(`上传文件大小不能超过 ${limitSize}MB!`);
          return false;
        }
        confClone.data.key = Date.now() + "_" + file.name;
        confClone.data.token = that.uptoken;
        confClone.action = that.uploadAction;
      };
    }

    // 组件改造，兼容旧json
    compatible(this, confClone)

    // 如果slots文件夹存在与当前tag同名的文件，则执行文件中的代码
    mountSlotFiles.call(this, h, confClone, children);

    // 将字符串类型的事件，发送为消息
    emitEvents.call(this, confClone);

    // 将json表单配置转化为vue render可以识别的 “数据对象（dataObject）”
    buildDataObject.call(this, confClone, dataObject);
    // 应用程序特殊处理
    // 下拉框组件增加popper-append-to-body
    if (dataObject.attrs && this.conf.__config__.tag == "el-select") {
      dataObject.attrs['popper-append-to-body'] = true
    }
    if (dataObject.attrs && this.conf.__config__.tag == "el-time-picker" || this.conf.__config__.tag == "el-date-picker") {
      dataObject.attrs['append-to-body'] = true
    }
    // 禁用默认的滚轮行为
    if (this.conf.__config__.tag == "el-input") {
      dataObject.nativeOn = {
        mousewheel: (event) => {
          event.preventDefault();
        }
      }

    }

    dataObject = setFixedComponent(this.conf, dataObject);
    return h(this.conf.__config__.tag, dataObject, children);
  },
};
