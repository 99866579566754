var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "加签",
        visible: _vm.dialogVisible,
        width: "60%",
        "modal-append-to-body": false,
        "custom-class": "addSignature-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          },
          on: { close: _vm.close }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "加签审批人", prop: "userIdList" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "popper-append-to-body": false,
                    filterable: "",
                    clearable: "",
                    multiple: "",
                    placeholder: "请选择加签审批人"
                  },
                  model: {
                    value: _vm.ruleForm.userIdList,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "userIdList", $$v)
                    },
                    expression: "ruleForm.userIdList"
                  }
                },
                _vm._l(_vm.userOptions, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.userName, value: item.userId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "加签方式", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "popper-append-to-body": false,
                    clearable: "",
                    placeholder: "请选择加签方式"
                  },
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type"
                  }
                },
                _vm._l(_vm.typeOptions, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ruleForm.type === "before",
                      expression: "ruleForm.type === 'before'"
                    }
                  ],
                  staticClass: "sign-explain"
                },
                [_vm._v("加签后 流程先经过被加签人")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ruleForm.type === "after",
                      expression: "ruleForm.type === 'after'"
                    }
                  ],
                  staticClass: "sign-explain"
                },
                [_vm._v("加签后 流程审批后经过被加签人")]
              ),
              _c("span")
            ],
            1
          ),
          _vm.ruleForm.userIdList.length > 1
            ? _c(
                "el-form-item",
                { attrs: { label: "审批方式", prop: "approveType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "popper-append-to-body": false,
                        clearable: "",
                        placeholder: "请选择审批方式"
                      },
                      model: {
                        value: _vm.ruleForm.approveType,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "approveType", $$v)
                        },
                        expression: "ruleForm.approveType"
                      }
                    },
                    _vm._l(_vm.approveTypeOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请填写备注" },
                model: {
                  value: _vm.ruleForm.reason,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "reason", $$v)
                  },
                  expression: "ruleForm.reason"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("div", { staticClass: "dialog-footer" }, [
            _c(
              "div",
              {
                staticClass: "dialog-footer-btn",
                on: {
                  click: function($event) {
                    _vm.dialogVisible = false
                  }
                }
              },
              [_vm._v(" 取消 ")]
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer-btn-determine",
                on: {
                  click: function($event) {
                    return _vm.fangd("ruleForm")
                  }
                }
              },
              [_vm._v(" 确认加签 ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }