var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联审批单",
            visible: _vm.revokeShow,
            width: "600px",
            "modal-append-to-body": false,
            "custom-class": "_relatedApproval"
          },
          on: {
            "update:visible": function($event) {
              _vm.revokeShow = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": "top" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "我审批的", name: "0" } }),
              _c("el-tab-pane", { attrs: { label: "我发起的", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "我收到的", name: "2" } })
            ],
            1
          ),
          _c("el-input", {
            attrs: {
              placeholder: "流程名称/摘要/发起人",
              "prefix-icon": "el-icon-search"
            },
            on: { input: _vm.deGetRelationApprovalDetail },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _c(
            "div",
            { staticClass: " select-box" },
            [
              _c("span", [_vm._v("审批类型 ")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "审批类型" },
                  on: { change: _vm.getRelationApprovalDetail },
                  model: {
                    value: _vm.formId,
                    callback: function($$v) {
                      _vm.formId = $$v
                    },
                    expression: "formId"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-content" },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                { key: item.processInstanceId, staticClass: "tab-pane" },
                [
                  _c("div", { staticClass: "tab-pane-header" }, [
                    _c("div", { staticClass: "tab-pane-header-left" }, [
                      _c("div", { staticStyle: { "margin-right": "5px" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedItems,
                              expression: "selectedItems"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: item,
                            checked: Array.isArray(_vm.selectedItems)
                              ? _vm._i(_vm.selectedItems, item) > -1
                              : _vm.selectedItems
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.selectedItems,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = item,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedItems = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedItems = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedItems = $$c
                              }
                            }
                          }
                        })
                      ]),
                      _c("div", [
                        item.initiator || item.initiator.avatarUrl
                          ? _c("img", {
                              attrs: { src: item.initiator.avatarUrl, alt: "" }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/ioc/Group_41.png"),
                                alt: ""
                              }
                            })
                      ]),
                      _c("div", { staticClass: "tab-pane-header-right" }, [
                        _c(
                          "div",
                          { staticClass: "tab-pane-header-right-title" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "div",
                          { staticClass: "tab-pane-header-right-time" },
                          [
                            _vm._v(
                              "提交于" +
                                _vm._s(_vm.formattedTime(item.createTime)) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "tab-pane-status" }, [
                      item.status == 1
                        ? _c("span", { staticStyle: { color: "#5669ec" } }, [
                            _vm._v("进行中")
                          ])
                        : _vm._e(),
                      item.status == 2 && item.result == 2
                        ? _c("span", { staticStyle: { color: "#67c23a" } }, [
                            _vm._v("已通过")
                          ])
                        : _vm._e(),
                      item.status == 2 && item.result == 3
                        ? _c("span", { staticStyle: { color: "#f83e45" } }, [
                            _vm._v("不通过")
                          ])
                        : _vm._e(),
                      item.status == 3
                        ? _c("span", { staticStyle: { color: "#848484" } }, [
                            _vm._v("已撤回")
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "tab-pane-content" }),
                  _c(
                    "div",
                    { staticClass: "tab-pane-footer" },
                    _vm._l(_vm.handleDigest(item.digest), function(row, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "tab-pane-footer-item" },
                        [
                          _c("span", { staticClass: "tab-pane-footer-title" }, [
                            _vm._v(_vm._s(row.name))
                          ]),
                          _c("span", [_vm._v(_vm._s(row.value))])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn",
                  on: {
                    click: function($event) {
                      _vm.revokeShow = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-determine",
                  on: { click: _vm.OnDetermine }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }