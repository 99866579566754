import store from "@/store";

export function switchDigest(value, index, variables) {
  console.log(`output->variables`, variables);
  let val = variables[value.__vModel__];
  console.log(`output->value`, value);
  if (value.__config__.type == "department") {
    //部门
    let department = store.state.imWorkbench.department;
    console.log(`output->department`, department);
    if (val && val.length > 0) {
      if (value.props.props.multiple) {
        let arr = [];
        val.forEach(item => {
          let result = searchNode(department[0], item[item.length - 1]);
          arr.push(result ? result : "--");
        });
        return arr.join(",");
      } else {
        const result = searchNode(department[0], val[val.length - 1]);
        if (result == null) {
          return "--";
        } else {
          2;
          return result;
        }
      }
    } else {
      return "";
    }
  } else if (value.__config__.type == "position") {
    //职位
    if (val) {
      let position = store.state.imWorkbench.position;
      if (value.multiple) {
        if (val.length && val.length > 0) {
          let arr = [];
          val.forEach(i => {
            let it = position.find(item => item.postId == i);
            arr.push(it ? it.postName : "--");
          });
          return arr.join(",");
        } else {
          return "";
        }
      } else {
        const it = position.find(item => item.postId == val);
        return it.postName;
      }
    } else {
      return "";
    }
  } else if (value.__config__.type == "rank") {
    //职级
    if (val) {
      let rank = store.state.imWorkbench.rank;
      if (value.multiple) {
        if (val.length && val.length > 0) {
          let arr = [];
          val.forEach(i => {
            let it = rank.find(item => item.rankId == i);
            arr.push(it ? it.rankName : "--");
          });
          return arr.join(",");
        } else {
          return "";
        }
      } else {
        const it = rank.find(item => item.rankId == val);
        return it.rankName;
      }
    } else {
      return "";
    }
  } else if (
    value.__config__.type == "contacts" ||
    value.__config__.type == "litigant"
  ) {
    //联系人
    if (val) {
      let allPersonnel = store.state.imWorkbench.allPersonnel;
      if (value.multiple) {
        if (val.length && val.length > 0) {
          let arr = [];
          val.forEach(i => {
            let it = allPersonnel.find(item => item.userId == i);
            console.log(`output->it`,it)
            arr.push(it ? it.userName : "--");
          });
          return arr.join(",");
        } else {
          return "";
        }
      } else {
        const it = allPersonnel.find(item => item.userId == val);
        
        return it?it.userName:'';
      }
    } else {
      return "";
    }
  } else if (value.__config__.type == "approval") {
    //关联审批
    if (val && val.length > 0) {
      let str = val.map(item => item.label).join(",");
      return str;
    } else {
      return "";
    }
  } else if (
    value.__config__.tag == "el-input" ||
    value.__config__.tag == "el-input-number" ||
    value.__config__.tag == "el-rate" ||
    value.__config__.tag == "el-slider"
  ) {
    //单行文本
    if (val) {
      return val;
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-select") {
    //选择
    if (val) {
      if (value.multiple) {
        if (val.length && val.length > 0) {
          return val.join(",");
        } else {
          return "";
        }
      } else {
        return val;
      }
      o;
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-cascader") {
    //联级选择
    if (val && val.length > 0) {
      if (value.props.props.multiple) {
        let arr = [];
        val.forEach(e => {
          arr.push(e[e.length - 1]);
        });
        return arr.join(",");
      } else {
        return val[val.length - 1];
      }
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-radio-group") {
    if (val) {
      return val;
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-checkbox-group") {
    if (val && val.length > 0) {
      let str = val.join(",");
      return str;
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-switch") {
    if (val) {
      return "是";
    } else {
      return "否";
    }
  } else if (value.__config__.tag == "el-time-picker") {
    if (val) {
      if (value["is-range"] == true) {
        if (val.length == 2) {
          let str = val[0] + "至" + val[1];
          return str;
        } else {
          return "";
        }
      } else {
        return val;
      }
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-date-picker") {
    if (val) {
      if (value.type == "daterange") {
        if (val.length == 2) {
          let str = val[0] + "至" + val[1];
          return str;
        }else{
            return ''
        }
      } else {
        return val;
      }
    } else {
      return "";
    }
  } else if (value.__config__.tag == "el-upload") {
    if (val && val.length > 0) {
      let str = val.map(item => item.fileName).join(",");
      return str;
    } else {
      return "";
    }
  }
}

const searchNode = (node, nodeId) => {
  if (node.id === nodeId) {
    return node.label;
  } else if (node.children !== undefined) {
    for (let i = 0; i < node.children.length; i++) {
      const result = searchNode(node.children[i], nodeId);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
};
